import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"

const ContactPage = ({ t: trans, intl }) => (
  <Layout>
    <SEO title="Contact" />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>{t({ intl: trans, id: "headerContactUs" })}</h1>
          <p>{t({ intl: trans, id: "textContactUs" })}</p>
        </div>

        <div className={"column"}>
          <div className={"col-6"}>
            <div className={"contact-items"}>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdDyeIaub4ND39IRcCF4-bgmo4pNPzZ42IHQM5P7blc_MHG-g/viewform?embedded=true"
                width="640"
                height="864"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >
                {t({ intl: trans, id: "textLoading" })}
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default withTrans(ContactPage)
